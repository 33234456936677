import React from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql, Link } from "gatsby"
import ImageRow from "../components/ImageRow"
import TextSectionBackground from "../components/TextSectionBackground"
import { SectionHeader, SubSectionHeader } from "../components/Typography"
import { ActionButton, ButtonBasic } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import styled from "styled-components"
import SEO from "../components/SEO"

const ButtonWrapper = styled.div`
  margin: 2rem 0 2rem -10px;
  button {
    margin: 8px 10px;
  }

`

const Accommodation = () => {
  const data = useStaticQuery(graphql`
    query {
      images: contentfulGallery(name: { eq: "Accommodation" }) {
        images {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <PageLayout>
      <SEO 
      title="Luxury Safari Tents"
      description="Accommodation at Mavela is in luxury safari tents with en suite shower facilities, a private deck with beautiful views over the surrounding bushveld."
      />
      <TextSectionBackground pa="5% 7% 2%">
      <SectionHeader>Accommodation</SectionHeader>
        <ImageRow
          pic={data.images.images[1].fluid}
          header="Safari-Style Tented Accommodation"
          text={
            <>
              Accommodation is in seven luxury, safari-style tents with en suite
              shower facilities, a private deck with beautiful views over the
              surrounding bushveld. With comfortable deck chairs and a birdbath
              in front of each tent, birding enthusiasts can enjoy the bird life
              with ease from their rooms. Crisp white linen is used on either
              king or single beds, the tents have been tastefully decorated
              complete with aircons, standing fans and luxurious Charlotte Rhys amenities.
              The camp is unfenced and guests are escorted back to their rooms
              after dinner. Mavela Game Lodge offers an intimate safari lodge
              experience in a unique part of the African bush. 
              <ButtonWrapper>
                <EnquireButton />
                <ActionButton>
                  <a
                    href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Check Availability
                  </a>
                </ActionButton>
                <ButtonBasic>
                  <a
                    href="/RackRates.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download Rates Sheet
                  </a>
                </ButtonBasic>
              </ButtonWrapper>             
            </>
          }
          richText={false}          
        >
          
        </ImageRow>
        
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Accommodation
